import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../utils/variables';

export const HeaderNavbar = styled.nav`
    min-height: 5.5rem;
    background-color: #ffffff !important;
    box-shadow: 0 2px 20px 4px rgba(142, 81, 199, 0.05);
    will-change: min-height;
    position: relative;
    z-index: 30;
`;

export const FooterNavbar = styled.nav`
    min-height: 5.5rem;
    background-color: #ffffff !important;
    box-shadow: 0 2px 20px 4px rgba(142, 81, 199, 0.05);
    will-change: min-height;
    position: relative;
    z-index: 30;

    @media screen and (max-width: 860px) {
        box-shadow: none;
    }
`;

export const NavBarItem = styled(Link)`
    color: ${colors.SECONDARY};
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    position: relative;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    border-radius: 10px;
    align-items: center;
    transition-duration: 0.075s;
`;
export const FlexContainer = styled.div`
    align-items: center;
    min-height: 5.5rem;
    width: 100%;
    display: flex;

    @media (max-width: 1023px) {
        display: block;
    }
`;
