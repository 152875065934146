import 'aos/dist/aos.css';
import AOS from 'aos';
import React, { Component } from 'react';
import { GeolocationControl, GeoObject, Map, YMaps } from 'react-yandex-maps';
import Contacts from './contacts';

const mapData = {
    center: [53.87730751829536, 30.33734925231518],
    zoom: 17.25,
};

const isBrowser = typeof window !== 'undefined';

export default class extends Component {
    constructor(props, context) {
        super(props, context);

        if (isBrowser) AOS.init();

        this.state = {
            width: isBrowser ? document.documentElement.clientWidth : 0,
            height: isBrowser ? document.documentElement.clientHeight / 1.5 : 0,
        };
    }

    componentWillReceiveProps() {
        if (isBrowser) AOS.refresh();
    }

    render() {
        const { height, width } = this.state;
        return (
            <section className="hero is-platform" data-aos="fade-up" data-aos-duration="500">
                <YMaps>
                    <Map defaultState={mapData} width={width} height={height}>
                        <GeoObject
                            geometry={{
                                type: 'Point',
                                coordinates: [53.87730751829536, 30.33734925231518],
                            }}
                            options={{
                                preset: 'islands#redStretchyIcon',
                                draggable: false,
                            }}
                        />
                        <GeolocationControl />
                    </Map>
                </YMaps>
                <Contacts />
            </section>
        );
    }
}
