import styled from 'styled-components';
import { ICardProps } from '../interfaces/props/ICardProps';

export const CardColumnContainer = styled.div`
    margin-bottom: 2rem;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        justify-content: center;
    }
    @media screen and (min-width: 482px) {
        display: flex;
    }
    @media screen and (max-width: 482px) {
        margin-bottom: 0.5rem;
    }
`;

export const CardColumn = styled.div`
    flex: 1;
    @media screen and (max-width: 768px) {
        flex: none;
        width: 40%;
    }
    @media screen and (max-width: 520px) {
        flex: none;
        width: 100%;
    }
`;
export const Cards = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 640px;
    background-color: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    transition-duration: 172ms;
    will-change: transform, box-shadow;
    overflow: hidden;
    z-index: 20;
    height: 100%;

    &:hover {
        transition-duration: 172ms!important;
        box-shadow: 0 10px 20px rgba(220, 220, 220, 0.5)!important;
        transform: translateY(-8px)!important;
        color: inherit!important;
    }
`;
export const CardImage = styled.img`
    border-radius: 10px 10px 0 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition-duration: 172ms;
    will-change: transform;
    display: block;
    position: relative;
    z-index: 0;

    ${Cards}:hover & {
        transform: scale(1.03);
    }
`;
export const CardBody = styled.div`
    flex: 1;
    padding: 2rem 1.5rem;
    height: 100%;
`;
export const CardFooter = styled.div<ICardProps>`
    display: flex;
    align-items: stretch;
    border-top: none;
    padding: ${props => (props.hasButton ? 2 : 0)}rem ${props => (props.hasButton ? 1.5 : 0)}rem;
    margin-top: ${props => (props.hasButton ? -3 : 0)}rem;
`;

CardFooter.defaultProps = {
    hasButton: false,
};
