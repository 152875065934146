import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ButtonWarning } from '../../components-shared/buttons';
import { TitleOffer } from '../../components-shared/typography';
import { ISpecialOfferContent } from '../../interfaces/content/ISpecialOfferContent';

export const useSpecialOffers = () => {
    const nodes = useStaticQuery(
        graphql`
            {
                allContentfulSpecialOffer(sort: { order: ASC, fields: [createdAt] }) {
                    edges {
                        node {
                            offerText {
                                offerText
                            }
                            buttonText
                            isReversedDirection
                            image {
                                file {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        `,
    );
    return nodes.allContentfulSpecialOffer.edges;
};
export default (props: ISpecialOfferContent) => {
    const {
        node: {
            offerText: { offerText },
            buttonText,
            isReversedDirection,
            image: {
                file: { url },
            },
        },
    } = props;

    return (
        <section className="hero section is-platform is-warning">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-12">
                        <div
                            className="columns is-centered is-vcentered"
                            style={isReversedDirection ? { flexDirection: 'row-reverse', textAlign: 'right' } : {}}
                        >
                            <div className="column is-6">
                                <div className="section-header">
                                    <TitleOffer>
                                        {offerText}
                                        <span className="spacer" />
                                        <span className="tag is-light" style={{ verticalAlign: 'middle' }}>
                                            Акция!
                                        </span>
                                    </TitleOffer>
                                    <ButtonWarning className="button is-medium">{buttonText}</ButtonWarning>
                                </div>
                            </div>
                            <div className="column has-text-centered">
                                <div className="section-media animated-scale-up-center">
                                    <img src={url} alt={offerText} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
