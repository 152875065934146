import { Link } from 'gatsby';
import React, { Component } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { CardTitle, Price } from '../../components-shared/typography';
import { CardBody, CardColumn, CardFooter, CardImage, Cards } from '../../components-shared/styled-cards';
import { ICardContent } from '../../interfaces/content/ICardContent';

const isBrowser = typeof window !== 'undefined';

export class PopularCard extends Component<ICardContent> {
    constructor(props, context) {
        super(props, context);
        if (isBrowser) AOS.init();
    }

    componentWillReceiveProps() {
        if (isBrowser) AOS.refresh();
    }

    render() {
        const {
            node: {
                title,
                price,
                term,
                hasSpecialOffer,
                backgroundImage: {
                    file: { url },
                },
            },
        } = this.props;

        return (
            <CardColumn className="column is-4">
                <Cards data-aos="zoom-in-up" data-aos-duration="500">
                    <Link>
                        <CardImage src={url} />
                    </Link>
                    <CardBody>
                        <Link>
                            <CardTitle style={{ height: 72 }}>{title}</CardTitle>
                            {!!price && price > 0 ? <Price className="has-text-danger">{price}р.</Price> : null}
                            <CardFooter>
                                <span className="tag is-link">{term}</span>
                                <span className="spacer" />
                                <span className={hasSpecialOffer ? 'tag is-warning' : 'is-invisible'}>Акция!</span>
                            </CardFooter>
                        </Link>
                    </CardBody>
                </Cards>
            </CardColumn>
        );
    }
}
