import React, { useState } from 'react';
import { Link } from 'gatsby';
import { TitleSecondaryLogo } from '../../components-shared/typography';
import { FlexContainer, HeaderNavbar, NavBarItem } from './styled';

export default () => {
    const [isHam, setHam] = useState(false);

    return (
        <header className="app-header">
            <HeaderNavbar role="navigation">
                <FlexContainer className="container">
                    <div className="navbar-brand">
                        <div className="navbar-item">
                            <Link to="/">
                                <TitleSecondaryLogo>Альянс</TitleSecondaryLogo>
                            </Link>
                        </div>
                        <a
                            role="button"
                            className={isHam ? 'navbar-burger is-active' : 'navbar-burger'}
                            data-target="navMenu"
                            aria-label="menu"
                            aria-expanded="false"
                            onClick={(): void => setHam(!isHam)}
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </a>
                    </div>
                    <div
                        className={isHam ? 'navbar-menu is-active' : 'navbar-menu'}
                        id="target"
                        style={{ boxShadow: 'none' }}
                    >
                        <div className="navbar-end">
                            <NavBarItem className="navbar-item" to="#popular">
                                🎓 Обучение и стоимость
                            </NavBarItem>
                            <NavBarItem className="navbar-item" to="#activities">
                                🙌🏻 Мероприятия
                            </NavBarItem>
                            <NavBarItem className="navbar-item" to="#contacts">
                                📇 Контакты
                            </NavBarItem>
                        </div>
                    </div>
                </FlexContainer>
            </HeaderNavbar>
        </header>
    );
};
