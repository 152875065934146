import { graphql, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { ButtonOrange } from '../../components-shared/buttons';
import { Pre, Title } from '../../components-shared/typography';
import { ITrialContent } from '../../interfaces/content/ITrialContent';

const isBrowser = typeof window !== 'undefined';

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        if (isBrowser) AOS.init();
    }

    componentWillReceiveProps() {
        if (isBrowser) AOS.refresh();
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        contentfulBanner {
                            title
                            mainText {
                                mainText
                            }
                            buttonText
                            backgroundImage {
                                file {
                                    url
                                }
                            }
                        }
                    }
                `}
                render={data => <TrialBanner node={data.contentfulBanner} />}
            />
        );
    }
}

const TrialBanner = (props: ITrialContent) => {
    const {
        node: {
            title,
            mainText: { mainText },
            buttonText,
            backgroundImage: {
                file: { url },
            },
        },
    } = props;

    return (
        <section className="hero section is-platform">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-12">
                        <div className="columns is-centered">
                            <div className="column is-7" data-aos="zoom-in-up" data-aos-duration="350">
                                <div className="section-header">
                                    <Title>{title}</Title>
                                    <Pre>{mainText}</Pre>
                                    <ButtonOrange className="button is-medium">
                                        {buttonText}
                                        <span className="spacer" />
                                        <img src="right-arrow.svg" className="image is-24x24" alt={buttonText} />
                                    </ButtonOrange>
                                </div>
                            </div>
                            <div className="column has-text-centered" data-aos="zoom-in-up" data-aos-duration="750">
                                <div className="section-media">
                                    <img src={url} alt={title} width="320" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
