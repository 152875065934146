import React from 'react';

import Header from './header';
import Footer from './footer';
import { ILayoutProps } from '../../interfaces/props/ILayoutProps';

export default ({ children }: ILayoutProps) => (
    <>
        <Header />
        {children}
        <Footer />
    </>
);
