import React from 'react';
import { colors } from '../../utils/variables';
import { ButtonWhite } from '../../components-shared/buttons';
import { TitleSecondaryWhite } from '../../components-shared/typography';
import { Section, TrialInput, Wrapper } from './styled';
import { IEnterTrialProps } from '../../interfaces/props/IEnterTrialProps';

export default (props: IEnterTrialProps) => {
    const { headText } = props;
    return (
        <Section className="hero">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered is-vcentered">
                        <div className="column has-text-centered">
                            <TitleSecondaryWhite className="is-spaced" color={colors.WHITE}>
                                {headText}
                            </TitleSecondaryWhite>
                        </div>
                    </div>
                    <div className="columns is-centered is-vcentered">
                        <Wrapper className="column is-5">
                            <TrialInput type="text" className="input is-medium" placeholder="Ваше имя" />
                        </Wrapper>
                        <Wrapper className="column is-5">
                            <TrialInput type="text" className="input is-medium" placeholder="Номер телефона" />
                        </Wrapper>
                    </div>
                    <div className="section-footer has-text-centered">
                        <ButtonWhite className="button is-medium">Записаться на пробное занятие</ButtonWhite>
                    </div>
                </div>
            </div>
        </Section>
    );
};
