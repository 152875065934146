import React, { Component } from 'react';
import { Link } from 'gatsby';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CardBody, CardColumn, CardFooter, CardImage, Cards } from '../../components-shared/styled-cards';
import { Caption, CardTitle } from '../../components-shared/typography';
import { ButtonCard } from '../../components-shared/buttons';
import { ICardContent } from '../../interfaces/content/ICardContent';

const isBrowser = typeof window !== 'undefined';

export class ActivityCard extends Component<ICardContent> {
    constructor(props, context) {
        super(props, context);
        if (isBrowser) AOS.init();
    }

    componentWillReceiveProps() {
        if (isBrowser) AOS.refresh();
    }

    render() {
        const {
            node: {
                title,
                description: { description },
                backgroundImage: {
                    file: { url },
                },
            },
        } = this.props;

        return (
            <CardColumn className="column is-4-desktop">
                <Cards data-aos="zoom-in-up" data-aos-duration="500">
                    <Link>
                        <CardImage src={url} />
                    </Link>
                    <CardBody>
                        <Link>
                            <CardTitle>{title}</CardTitle>
                            <Caption className="card-text">{description}</Caption>
                        </Link>
                    </CardBody>
                    <CardFooter hasButton>
                        <ButtonCard className="button is-medium">Подробности</ButtonCard>
                    </CardFooter>
                </Cards>
            </CardColumn>
        );
    }
}
