import styled from 'styled-components';
import { colors } from '../utils/variables';

export const Title = styled.h1`
    color: ${colors.PRIMARY};
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.125;

    &:first-child {
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;

        &:first-child {
            margin-bottom: 1rem;
        }
    }
`;
export const TitleOffer = styled.h2`
    color: ${colors.MADRAS};
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.125;

    &:first-child {
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;

        &:first-child {
            margin-bottom: 1rem;
        }
    }
`;
export const TitleSecondary = styled.h3`
    color: ${colors.PRIMARY};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.125;
`;
export const TitleSecondaryLogo = styled.h3`
    color: ${colors.LOGO};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.125;
`;
export const TitleSecondaryWhite = styled.h3`
    color: ${colors.WHITE};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.125;
`;
export const CardTitle = styled.h2`
    color: ${colors.PRIMARY};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.125;

    &:first-child {
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 480px) {
        &:first-child {
            margin-bottom: 1rem;
        }
    }
`;
export const Caption = styled.h2`
    color: ${colors.SECONDARY};
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
`;
export const Pre = styled.pre`
    font-weight: 600;
    color: ${colors.SECONDARY};
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 1rem 0 !important;
    background-color: transparent !important;
    white-space: pre-wrap !important;
`;
export const Price = styled.h4`
    font-size: 2.5rem;
    font-weight: 700;
    margin: 12px 0;
`;
