import 'bulma/css/bulma.min.css';
import '../styles/all.css';
import React from 'react';

import Ages from '../components/ages/ages';
import EnterTrial from '../components/enter-trial/enter-trial';
import Layout from '../components/layout/layout';
import Popular from '../components/popular/popular';
import TrialBanner from '../components/trial/trial';
import SpecialOffer, { useSpecialOffers } from '../components/special-offer/special-offer';
import Activities from '../components/activities/activities';
import Map from '../components/contacts/map';

export default () => {
    const specialOfferNodes = useSpecialOffers();
    // document.title = 'Альянс - Английский, когда нужен результат';

    return (
        <Layout>
            <TrialBanner />
            <Ages />
            <EnterTrial headText="Быстро. Интересно. Недорого." />
            <Popular />
            <SpecialOffer node={specialOfferNodes[0].node} />
            <Activities />
            <SpecialOffer node={specialOfferNodes[1].node} />
            <EnterTrial headText="Запишитесь на пробное занятие" />
            <Map />
        </Layout>
    );
};
