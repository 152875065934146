import { graphql, Link, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

import { Holder } from '../../components-shared/holder';
import { Styled } from './styled';
import { IAgesContent } from '../../interfaces/content/IAgesContent';

export default function() {
    return (
        <StaticQuery
            query={graphql`
                {
                    contentfulAges {
                        schoolchild
                        teen
                        adult
                    }
                }
            `}
            render={data => <Ages node={data.contentfulAges} />}
        />
    );
}

const isBrowser = typeof window !== 'undefined';

class Ages extends Component<IAgesContent> {
    constructor(props, context) {
        super(props, context);
        if (isBrowser) AOS.init();
    }

    componentWillReceiveProps() {
        if (isBrowser) AOS.refresh();
    }

    render() {
        const {
            node: { schoolchild, teen, adult },
        }: IAgesContent = this.props;

        return (
            <section className="section">
                <div className="container">
                    <div className="columns is-variable is-6 is-centered">
                        <Styled className="column is-3 is-4-fullhd is-4-desktop">
                            <Link>
                                <Holder>{schoolchild}</Holder>
                            </Link>
                        </Styled>
                        <Styled className="column is-3 is-4-fullhd is-4-desktop">
                            <Link>
                                <Holder>{teen}</Holder>
                            </Link>
                        </Styled>
                        <Styled className="column is-3 is-4-fullhd is-4-desktop">
                            <Link>
                                <Holder>{adult}</Holder>
                            </Link>
                        </Styled>
                    </div>
                </div>
            </section>
        );
    }
}
