import styled from 'styled-components';
import { colors } from '../utils/variables';

export const Button = styled.a`
    background-color: ${colors.BLUE};
    color: ${colors.WHITE};
    border: 0 transparent;
    border-radius: 6px;
    font-weight: 600;
    font-size: ${1.25}rem;
    padding: 1.5rem 1.5rem;
    transition-duration: 75ms;
    white-space: normal;

    @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 450px) {
        padding: 0.5rem 0.45rem;
        height: 100%;
    }
`;

export const ButtonPrimary = styled(Button)`
    background-color: ${colors.BLUE};
    color: ${colors.WHITE};

    &:hover {
        background-color: ${colors.BLUE_HOVER};
        color: ${colors.WHITE};
    }
`;

export const ButtonWhite = styled(Button)`
    background-color: ${colors.WHITE};
    color: ${colors.BLUE};

    &:hover {
        color: ${colors.BLUE_HOVER};
        background-color: ${colors.WHITE_HOVER};
    }
`;

export const ButtonWarning = styled(Button)`
    background-color: ${colors.WHITE};
    color: ${colors.DARK};

    &:hover {
        background-color: ${colors.WHITE_HOVER};
        color: ${colors.DARK};
    }
`;

export const ButtonOrange = styled(Button)`
    background-color: ${colors.ORANGE};
    color: ${colors.WHITE};

    &:hover {
        background-color: ${colors.ORANGE_HOVER};
        color: ${colors.WHITE};
    }
`;

export const ButtonCard = styled(Button)`
    background-color: ${colors.WHITE};
    color: ${colors.BLUE};
    border: 3px ${colors.BLUE} solid;

    &:hover {
        color: ${colors.WHITE};
        background-color: ${colors.BLUE_HOVER};
        border: 3px ${colors.BLUE_HOVER} solid;
    }
`;
