import React, { ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { PopularCard } from './popular-card';
import { TitleSecondary } from '../../components-shared/typography';
import { CardColumnContainer } from '../../components-shared/styled-cards';
import { ButtonPrimary } from '../../components-shared/buttons';
import { ICardContent } from '../../interfaces/content/ICardContent';

export default function Popular() {
    return (
        <section className="hero section is-hero has-background-white-bis" id="popular">
            <div className="container">
                <div className="columns is-centered is-vcentered">
                    <div className="column has-text-centered">
                        <TitleSecondary>Популярные услуги</TitleSecondary>
                    </div>
                </div>
                <PopularCards
                    render={({ allContentfulPopularServices: { edges } }) =>
                        edges.map(({ node }: ICardContent) => <PopularCard key={node.id} node={node} />)
                    }
                />
                <div className="columns is-centered is-vcentered">
                    <div className="column has-text-centered">
                        <ButtonPrimary className="button is-medium">👇 Все услуги</ButtonPrimary>
                    </div>
                </div>
            </div>
        </section>
    );
}

function PopularCards(props: {
    render: ({
        allContentfulPopularServices: { edges },
    }: {
        allContentfulPopularServices: { edges: any };
    }) => ReactNode;
}) {
    const { render } = props;
    return (
        <CardColumnContainer className="columns">
            <StaticQuery
                query={graphql`
                    {
                        allContentfulPopularServices(sort: { order: ASC, fields: [createdAt] }) {
                            edges {
                                node {
                                    id
                                    title
                                    price
                                    term
                                    hasSpecialOffer
                                    backgroundImage {
                                        file {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={render}
            />
        </CardColumnContainer>
    );
}
