export const colors = {
    PRIMARY: `#01178c`,
    SECONDARY: `#74687b`,
    LOGO: '#463853',
    DARK: '#0a0a0a',
    MADRAS: '#4c421a',
    WHITE: `#FFFFFF`,
    WHITE_HOVER: '#f9f9f9',
    BLUE: `#3c388c`,
    BLUE_HOVER: '#01178c',
    ORANGE: '#ff5c35',
    ORANGE_HOVER: '#ff3f11',
};
