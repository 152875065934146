import styled from 'styled-components';

export const Section = styled.section`
    background-image: linear-gradient(141deg, #5d2cb9 0%, #8e51c7 71%, #ae5fd2 100%);
    color: #fff;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const Wrapper = styled.div`
    @media screen and (max-width: 525px) {
        width: 100%;
    }
`;
export const TrialInput = styled.input`
    background-color: rgba(255, 255, 255, 0.75);
    height: 2.375em;
    transition-duration: 86ms;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
`;
