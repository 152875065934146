import React, { ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { CardColumnContainer } from '../../components-shared/styled-cards';

export function ActivityCards(props: {
    render: ({ allContentfulActivities: { edges } }: { allContentfulActivities: { edges: any } }) => ReactNode;
}) {
    const { render } = props;
    return (
        <CardColumnContainer className="columns">
            <StaticQuery
                query={graphql`
                    {
                        allContentfulActivities(sort: { order: ASC, fields: [createdAt] }) {
                            edges {
                                node {
                                    id
                                    title
                                    description {
                                        description
                                    }
                                    backgroundImage {
                                        file {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={render}
            />
        </CardColumnContainer>
    );
}
