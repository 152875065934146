import styled from 'styled-components';
import { colors } from '../../utils/variables';
import { HolderStatic } from '../../components-shared/holder';

export const ContactsHolderStatic = styled(HolderStatic)`
    @media screen and (max-width: 860px) {
        border-radius: 10px 10px 0 0;
    }
`;

export const ContactsCard = styled.div`
    position: absolute;
    left: 70%;
    margin-top: 6rem;

    @media screen and (max-width: 860px) {
        flex-grow: 1;
        margin: 0 auto;
        position: static;
        width: 100%;
    }
`;

export const ContactIMG = styled.img`
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
`;

export const ContactLink = styled.a`
    font-size: 1rem;
    border-radius: 10px;
    color: ${colors.SECONDARY};
    display: block;
    padding: 0.5em 0.75em;
    font-weight: 600;
`;
