import styled from 'styled-components';

export const Styled = styled.div`
    text-align: center;
    @media screen and (max-width: 1200px) {
        flex: none;
        width: 33% !important;
    }
    @media screen and (max-width: 768px) {
        flex: none;
        width: 100% !important;
    }
`;
