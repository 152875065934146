import React from 'react';
import { ActivityCard } from './activity-card';
import { TitleSecondary } from '../../components-shared/typography';
import { ButtonPrimary } from '../../components-shared/buttons';
import { ICardContent } from '../../interfaces/content/ICardContent';
import { ActivityCards } from './activity-cards';

export default function Activities() {
    return (
        <section className="hero section is-hero has-background-white-bis" id="activities">
            <div className="container">
                <div className="columns is-centered is-vcentered">
                    <div className="column has-text-centered">
                        <TitleSecondary>Мероприятия</TitleSecondary>
                    </div>
                </div>
                <ActivityCards
                    render={({ allContentfulActivities: { edges } }) =>
                        edges.map(({ node }: ICardContent) => <ActivityCard key={node.id} node={node} />)
                    }
                />
                <div className="columns is-centered is-vcentered">
                    <div className="column has-text-centered">
                        <ButtonPrimary className="button is-medium">👇 Все мероприятия</ButtonPrimary>
                    </div>
                </div>
            </div>
        </section>
    );
}
