import React from 'react';
import { Link } from 'gatsby';
import { FlexContainer, FooterNavbar, NavBarItem } from './styled';
import { TitleSecondaryLogo } from '../../components-shared/typography';

export default () => {
    return (
        <footer className="app-footer is-small">
            <FooterNavbar role="navigation">
                <FlexContainer className="container">
                    <div className="navbar-brand">
                        <div className="navbar-item">
                            <Link to="/">
                                <TitleSecondaryLogo>Альянс</TitleSecondaryLogo>
                            </Link>
                        </div>
                    </div>
                    <div className="navbar-end is-multiline">
                        <NavBarItem className="navbar-item" to="#popular">
                            🎓 Обучение и стоимость
                        </NavBarItem>
                        <NavBarItem className="navbar-item" to="#activities">
                            🙌🏻 Мероприятия
                        </NavBarItem>
                        <NavBarItem className="navbar-item" to="#contacts">
                            📇 Контакты
                        </NavBarItem>
                    </div>
                </FlexContainer>
            </FooterNavbar>
        </footer>
    );
};
