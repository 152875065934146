import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Caption, TitleSecondary } from '../../components-shared/typography';
import { ContactIMG, ContactLink, ContactsCard, ContactsHolderStatic } from './styled';
import { IContactsContent } from '../../interfaces/content/IContactsContent';

export const useContacts = () => {
    return useStaticQuery(
        graphql`
            {
                node: contentfulContacts {
                    address
                    phoneVelcom
                    phoneMTS
                    eMail
                }
            }
        `,
    );
};

export default () => {
    const {
        node: { address, phoneVelcom, phoneMTS, eMail },
    }: IContactsContent = useContacts();

    return (
        <ContactsCard id="contacts">
            <ContactsHolderStatic>
                <TitleSecondary>Наши контакты</TitleSecondary>
                <hr />
                <ul>
                    <li>
                        <Caption>{address}</Caption>
                    </li>
                    <br />
                    <li>
                        <ContactLink href={`tel:${phoneVelcom}`}>
                            <ContactIMG src="Velcom.png" alt="Velcom" className="image is-32x32" />
                            {phoneVelcom}
                        </ContactLink>
                    </li>
                    <li>
                        <ContactLink href={`tel:${phoneMTS}`}>
                            <ContactIMG src="MTS.png" alt="MTS" className="image is-32x32" />
                            {phoneMTS}
                        </ContactLink>
                    </li>
                    <li>
                        <ContactLink href={`mailto:${eMail}`}>
                            <ContactIMG src="Mail.png" alt="MTS" className="image is-32x32" />
                            {eMail}
                        </ContactLink>
                    </li>
                </ul>
            </ContactsHolderStatic>
        </ContactsCard>
    );
};
